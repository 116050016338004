import React, {FC} from 'react';
import {Col} from 'react-bootstrap';
import Section from '../section';
import gq from '../../images/home/testimonials/gq.png';
import hunt from '../../images/home/testimonials/hunt.png';
import getapp from '../../images/home/testimonials/getapp.png';
import capterra from '../../images/home/testimonials/capterra.png';

import './styles.scss';

const trusted = [
  {
    name: 'GetApp',
    logo: getapp,
    id: 1,
  },
  {
    name: 'Capterra',
    logo: capterra,
    id: 2,
  },
  {
    name: 'GQ',
    logo: gq,
    id: 3,
  },
  {
    name: 'Product hunt',
    logo: hunt,
    id: 4,
  },
];

const TrustedSection: FC = () => {
  return (
    <Section dark sectionDots className="pb-4">
      <Col className="section-part">
        <div className="section-part mt-4">
          <h2 className="section-title">
            The secret sauce trusted by over
            <span className="section-article-color">
              &nbsp;130,000 <br /> businesses of all sizes.
            </span>
          </h2>
        </div>
        <div className="img-wrap mt-4">
          {trusted.map((e, index) => {
            return (
              <div className={`col-md-3 col-sm-6 col-6 mb-4 ${index <= 2 ? 'img-border' : ''} text-center`}>
                <img
                  width={174}
                  height={100}
                  src={e.logo}
                  alt={e.name}
                  key={e.id}
                  style={{maxWidth: '100%', height: 'auto'}}
                />
              </div>
            );
          })}
        </div>
      </Col>
    </Section>
  );
};

export default TrustedSection;
